import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Query } from "types/graphql-types"

import Single from "@layouts/Single"
import SEO from "@components/Seo"

import { BASE_URL } from "../../config/paths"

const SingleImageTemplate = ({ data }: { data: Pick<Query, "file"> }) => {
    const { file } = data

    if (!file || !file.childImageSharp || !file.childImageSharp.fluid) {
        return null
    }

    return (
        <Single>
            <SEO title={`#${String(file.id)}`} imageUrl={BASE_URL + file.childImageSharp.fluid.src} />
            <Img fluid={file.childImageSharp.fluid} />
        </Single>
    )
}

export const query = graphql`
    query($id: String!) {
        file(id: { eq: $id }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default SingleImageTemplate

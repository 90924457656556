import React from "react"
import { Box } from "rebass"

import { Footer } from "@components/Footer"
import { Header } from "@components/Header"

export interface ISingleProps {
    children: React.ReactNode
}

const SingleLayout = ({ children }: ISingleProps) => (
    <Box>
        <Header siteTitle="LST&FND" />
        <Box as="main" padding="20px" maxWidth="1400px">
            {children}
        </Box>
        <Footer />
    </Box>
)

export default SingleLayout
